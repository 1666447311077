<script setup lang="ts">
import { ListboxButton, ListboxLabel } from '@headlessui/vue';
import { computed, inject } from 'vue';

import { wsSelectInjectionKey } from './ws-select-injection-key';

defineOptions({
  inheritAttrs: false,
});

const context = inject(wsSelectInjectionKey);

if (!context) {
  throw new Error('WsSelectTrigger must be used within a WsSelect component');
}

const {
  modelValue,
  optionLabel,
  optionKey,
  label,
  labelPosition,
  variant,
  size,
  multiple,
  disabled,
  readonly,
  loading,
  searchInput,
  hasError,
  aid,
  clear,
  removeSelection,
} = context;

const hasValue = computed(() => {
  return multiple.value
    ? Array.isArray(modelValue.value) && modelValue.value.length > 0
    : modelValue.value != null;
});
</script>

<template>
  <!-- Outlined variant -->
  <ListboxButton
    v-if="variant === 'outlined'"
    v-slot="{ open }"
    class="relative block w-full cursor-default rounded border bg-white pe-10 ps-2.5 text-start focus:outline-2 focus:-outline-offset-1 focus:outline-primary disabled:!opacity-100"
    :class="[
      {
        'min-h-[45px]': size === 'md',
        'min-h-[48px]': size === 'lg',
        'ui-open:outline ui-open:outline-2 ui-open:-outline-offset-1 ui-open:outline-primary':
          searchInput == null,
        'text-gray-300': disabled,
      },
      hasError ? 'border-danger' : 'border-gray-200',
    ]"
    :aid="aid"
  >
    <ListboxLabel
      v-if="label && labelPosition === 'inside'"
      class="absolute"
      :class="
        multiple
          ? ['top-1/2 -translate-y-1/2', { 'sr-only': hasValue }]
          : [
              'transition-all',
              hasValue || open
                ? [
                    'top-[5px] text-[0.625rem] font-semibold',
                    { 'text-gray-400': !disabled },
                  ]
                : 'top-1/2 -translate-y-1/2',
            ]
      "
    >
      {{ label }}
    </ListboxLabel>

    <template v-if="multiple">
      <!-- Chips -->
      <span
        v-if="Array.isArray(modelValue) && modelValue.length > 0"
        class="flex gap-1.5"
        :aid="`${aid}_SELECTED`"
      >
        <span
          v-for="val in modelValue"
          :key="optionKey(val)"
          class="flex items-center rounded-[30px] bg-gray-100 px-3.5 py-1 text-sm font-semibold"
          :aid="`${aid}_SELECTED_${optionKey(val).toString()}`"
        >
          <span class="block truncate">{{ optionLabel(val) }}</span>

          <button
            v-if="!disabled && !readonly"
            type="button"
            class="ms-1.5 flex items-center justify-center"
            :aid="`${aid}_DELETE_SELECTED_${optionKey(val).toString()}`"
            @click.stop="removeSelection(val)"
            @keydown.stop
          >
            <span class="fa-regular fa-xmark" aria-hidden="true" />
          </button>
        </span>
      </span>

      <span v-else-if="!label || labelPosition === 'outside'" class="block">
        Select
      </span>
    </template>

    <template v-else>
      <span
        v-if="modelValue != null"
        class="block truncate"
        :class="{ 'mt-[18px]': label && labelPosition === 'inside' }"
        :aid="`${aid}_SELECTED`"
      >
        {{ optionLabel(modelValue) }}
      </span>

      <span v-else-if="!label || labelPosition === 'outside'" class="block">
        Select
      </span>
    </template>

    <!-- Chevron, loading, and readonly indicators -->
    <span class="absolute inset-y-0 end-0 flex items-center gap-3 pe-3">
      <span
        v-if="loading"
        class="fa-regular fa-spinner-third animate-spin text-primary"
        aria-hidden="true"
      />

      <span
        v-if="readonly"
        class="fa-solid fa-lock text-gray-200"
        aria-hidden="true"
      />
      <span v-else class="fa-regular fa-chevron-down" aria-hidden="true" />
    </span>
  </ListboxButton>

  <!-- Filter variant -->
  <span v-else-if="variant === 'filter'" class="flex gap-px">
    <ListboxButton
      class="flex min-h-8 cursor-default items-center ps-5 font-semibold focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary disabled:!opacity-100"
      :class="
        hasValue
          ? 'gap-2.5 rounded-s-[30px] bg-primary-300 pe-2.5 hover:bg-primary-300/80'
          : 'gap-2 rounded-[30px] border border-gray-200 !bg-white pe-5 hover:!bg-primary-50'
      "
      :aid="aid"
    >
      <ListboxLabel v-if="label" :class="{ 'sr-only': hasValue }">
        {{ label }}
      </ListboxLabel>

      <template v-if="hasValue">
        <span
          v-if="multiple && Array.isArray(modelValue)"
          :aid="`${aid}_SELECTED`"
        >
          {{ optionLabel(modelValue[0]) }}

          <template v-if="modelValue.length > 1">
            +{{ modelValue.length - 1 }}
          </template>
        </span>

        <span v-else-if="modelValue != null" :aid="`${aid}_SELECTED`">
          {{ optionLabel(modelValue) }}
        </span>
      </template>

      <span
        v-if="loading"
        class="fa-regular fa-spinner-third animate-spin"
        aria-hidden="true"
      />
      <span
        v-else
        class="fa-regular fa-chevron-down text-xs"
        aria-hidden="true"
      />
    </ListboxButton>

    <!-- Clear button -->
    <button
      v-if="hasValue"
      type="button"
      :disabled="disabled || readonly"
      class="flex cursor-default items-center rounded-e-[30px] bg-primary-300 px-3 hover:bg-primary-300/80 disabled:!opacity-100"
      @click.stop="clear"
    >
      <span class="fa-regular fa-xmark" aria-hidden="true" />
    </button>
  </span>

  <!-- Text variant -->
  <ListboxButton
    v-else-if="variant === 'text'"
    class="flex cursor-default items-center gap-2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary disabled:!opacity-100"
    :aid="aid"
  >
    <ListboxLabel v-if="label" :class="{ 'sr-only': hasValue }">
      {{ label }}
    </ListboxLabel>

    <template v-if="hasValue">
      <span
        v-if="multiple && Array.isArray(modelValue)"
        :aid="`${aid}_SELECTED`"
      >
        {{ optionLabel(modelValue[0]) }}

        <template v-if="modelValue.length > 1">
          +{{ modelValue.length - 1 }}
        </template>
      </span>

      <span v-else-if="modelValue != null" :aid="`${aid}_SELECTED`">
        {{ optionLabel(modelValue) }}
      </span>
    </template>

    <!-- Chevron/loading indicator -->
    <span
      v-if="loading"
      class="fa-regular fa-spinner-third animate-spin"
      aria-hidden="true"
    />
    <span
      v-else
      class="fa-regular fa-chevron-down text-xs"
      aria-hidden="true"
    />
  </ListboxButton>
</template>
